import FilterItem from './FilterItem.ts';
export default class OptionsStore{
    private _existsItems:Map<string, FilterItem> = new Map();
    private _fullTextItems:Map<string, FilterItem> = new Map();




    constructor(){

    }

    addExistItem(pItem:FilterItem){
        if(!this._existsItems.has(pItem.column)){
            this._existsItems.set(pItem.column,pItem);
        }
    }
    addFullTextItem(pItem:FilterItem){
        if(!this._fullTextItems.has(pItem.column)){
            this._fullTextItems.set(pItem.column,pItem);
                  //  this.operators.unshift({ name: 'search_fnc', title: getOperatorTitle('search_fnc')});
       // this.operator = "search_fnc";
        }
    }

}